import React, { FC, useMemo, useState } from 'react';
import cx from 'classnames';
import styles from './Step3.module.scss';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';
import { RadioGroup } from '@material-ui/core';
import { batch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import NavigationBtnControl from '../NavigationBtnControl/NavigationBtnControl';
import {
  popStep,
  pushStep,
  replaceStep,
  STEPS,
  updateIsDone,
  updateStep3,
} from 'redux/bookingFormSlice';
import { AUTH_SYS_TYPE } from 'utils/constants/common';
import { formatDobForBE } from '../utils/helperFunction';
import { signUpSocialThunk, signUpThunk } from 'redux/authSlice';
import { createBookingAPI } from 'services/APIs';
import { openToast } from 'redux/ToastSlice';
import moment from 'moment';
import {
  MINUTE_OF_A_BLOCK,
  convertTimeHHmmss,
} from 'utils/convertMinutesIntoHoursOfDay';
import PatientCard from './PatientCard/PatientCard';
import { loadOtpToken } from 'utils/storage';
import { formatFullPhoneNumber } from 'utils/formatPhoneNumber';
import NewPatientCard from './NewPatientCard/NewPatientCard';
import useIsLoadedInsideIframe from 'hooks/useIsLoadedInsideIframe';
import { DentistInfo } from 'pages/UpdatedSearchResultPage/utils';
import { ROUTES } from 'routes/config';
import { scheduleTracking } from 'utils/tracking/metaEventTracking';
import DetailDescriptionSection from '../DetailDescriptionSection/DetaiDescriptionSection';
import trackGoogle from 'utils/tracking/googleEventTracking';
import trackTiktok from 'utils/tracking/tiktokEventTracking';
import { ChairOptimization } from 'pages/ClinicDetailsPage/ClinicDetailsPage';

interface Step3Props {
  onClose: () => void;
  clinicId: string;
  practitionerId: string;
  date: string;
  serviceId: string;
  operatoryId?: string;
  timeBlock: number;
  bookingSummaryInfo: {
    avatar: string;
    title: string;
    serviceTitle: string;
    clinicName: string;
    clinicAddress: string;
    clinicEmail: string;
    clinicPhoneNumber: string;
  };
  dentist: DentistInfo;
  chairOptimizations?: ChairOptimization[];
}

export interface NewPatient {
  phoneNumber: string;
  dob: string;
  email: string;
  firstName: string;
  lastName: string;
}

export const PATIENT_LIST_TYPE = {
  NORMAL: 'normal-patient-list', // patient is allowed to book next appts
  INCOMPLETE_FUTURE_APPT: 'incomplete-future-appt', // patient who has future appts and is not allowed to book next appts
  INCOMPLETE_PAST_APPT: 'incomplete-past-appt', // patient who has appts in the past and is not allowed to book next appts
  SAME_TIME_APPT: 'same-time-appt', // patient who has appts at the same time and is not allowed to book next appts
};

export const NULL_PATIENT_ID = 'patient-id-null';

const Step3: FC<Step3Props> = ({
  onClose,
  clinicId,
  practitionerId,
  date,
  timeBlock,
  operatoryId,
  serviceId,
  bookingSummaryInfo,
  dentist,
  chairOptimizations,
}) => {
  const {
    bookingFormSlice: {
      step1,
      step2: { newPatient, signUp },
      step3: { patients, chosenPatientId },
      pageStack,
    },
    authSlice: { isLoggedIn, sysType, socialData },
    clinicDetailsSlice,
  } = useAppSelector((state) => state);

  const isLoadedInsideIframe = useIsLoadedInsideIframe();

  const containerClassName = cx({
    [styles['container']]: true,
    [styles['container-iframe']]: isLoadedInsideIframe,
  });

  const { search, pathname } = useLocation();

  const searchParams = new URLSearchParams(search);

  const dispatch = useAppDispatch();

  const previousPage = pageStack[pageStack.length - 2];

  const [isLoading, setIsLoading] = useState(false);

  const patient = useMemo(() => {
    return previousPage === STEPS.NEW_PATIENT
      ? { ...step1, ...newPatient, id: '' }
      : { ...signUp, id: '' };
  }, [newPatient, previousPage, signUp, step1]);

  const handleOnBackClick = () => {
    if (isLoggedIn) return onClose();
    dispatch(popStep());
  };

  const handleNotYouClick = () => {
    dispatch(pushStep(STEPS.NEW_PATIENT));
  };

  const createUser = async () => {
    if (
      isLoggedIn ||
      previousPage === STEPS.PATIENT_INFO ||
      (previousPage === STEPS.NEW_PATIENT && !socialData)
    ) {
      return;
    }

    const user = {
      email: patient.email,
      firstName: patient.firstName,
      lastName: patient.lastName,
      dob: formatDobForBE(patient.dob),
      phoneNumber: patient.phoneNumber,
    };

    if (sysType !== AUTH_SYS_TYPE.PASSWORD) {
      await dispatch(signUpSocialThunk(user));
      return;
    }

    const data = await dispatch(
      signUpThunk({
        ...user,
        password: signUp.password,
        sysType: AUTH_SYS_TYPE.PASSWORD,
      })
    );

    return (data?.payload as any).accessToken;
  };

  const normalizedPatientInfo = () => {
    let chosenPatient = null;

    if (chosenPatientId === NULL_PATIENT_ID) {
      chosenPatient = patients.find((patient: any) => patient.id === null);
    } else {
      chosenPatient = patients.find(
        (patient: any) => patient.id === chosenPatientId
      );
    }

    // Detect if a new input patient is the same as the list of patients
    const duplicateNewPatient =
      previousPage === STEPS.NEW_PATIENT &&
      patients.find(
        (patientItem) =>
          patientItem.email?.toLowerCase() === patient.email?.toLowerCase() &&
          patientItem.firstName?.toLowerCase() ===
            patient.firstName?.toLowerCase() &&
          patientItem.lastName?.toLowerCase() ===
            patient.lastName?.toLowerCase()
      );

    const patientInfo =
      chosenPatient && previousPage !== STEPS.NEW_PATIENT
        ? {
            id: chosenPatient.id,
            email: chosenPatient.email,
            firstName: chosenPatient.firstName,
            lastName: chosenPatient.lastName,
            dob: chosenPatient.dob,
            phoneNumber: chosenPatient.phoneNumber,
            isNewPatient: chosenPatient.isNewPatient,
          }
        : {
            email: patient.email,
            firstName: patient.firstName,
            lastName: patient.lastName,
            dob: formatDobForBE(patient.dob),
            phoneNumber: patient.phoneNumber,
            isNewPatient: true,
            ...(duplicateNewPatient && { id: duplicateNewPatient.id }),
          };

    return patientInfo;
  };

  const createBooking = async (
    patientInfo: {
      id?: string;
      firstName: string;
      lastName: string;
      dob: string;
      email: string;
      phoneNumber: string;
      isNewPatient: boolean;
    },
    tmpAccessToken?: string
  ) => {
    const { id, firstName, lastName, email, phoneNumber, dob } = patientInfo;

    if (!operatoryId) {
      throw new Error('Missing Operatory Id');
    }

    const code = searchParams.get('code');

    const payload = {
      isClinicBooking: false,
      appointmentDate: moment(date).format('YYYY-MM-DD'),
      clinicId,
      patients: [
        {
          id,
          firstName,
          lastName,
          dob,
          email,
          phoneNumber,
          operatoryId: operatoryId || '',
          serviceId: serviceId,
          doctorId: practitionerId,
          startTime: convertTimeHHmmss(timeBlock * MINUTE_OF_A_BLOCK),
          useInsurance: false,
          dentist,
        },
      ],
      ...(code && { code }),
      isSignedIn: Boolean(isLoggedIn),
      ...(chairOptimizations && { chairOptimizations }),
    };
    const data = await createBookingAPI(payload, {
      otpToken: loadOtpToken(step1.phoneNumber),
      tmpAccessToken,
    });
    return data;
  };

  const sendTracking = () => {
    const customScript = clinicDetailsSlice.clinicData.customScript;

    if (!customScript) return;

    const isGoogleAds = customScript.includes('gtag');

    const isTiktokTrack =
      customScript.includes('tiktok') &&
      customScript.includes('ttq.identify') &&
      customScript.includes('ttq.track');

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = customScript;

    const scriptElements = tempDiv.querySelectorAll('script');

    const isScriptLoaded =
      (window as any).dataLayer && (window as any).dataLayer.length > 0;

    if (!isScriptLoaded) {
      scriptElements.forEach((script) => {
        // SCRIPT DOES NOT RUN WHEN APPEND ORIGINAL SCRIPT
        const updatedScript = document.createElement('script');

        if (script.src) {
          updatedScript.src = script.src;
        } else {
          updatedScript.innerHTML = script.innerHTML;
        }

        document.head.appendChild(updatedScript);
      });
    }

    if (isGoogleAds) {
      trackGoogle();
    }

    if (isTiktokTrack) {
      trackTiktok(customScript);
    }
  };

  const handleOnSubmit = async () => {
    try {
      setIsLoading(true);
      const tmpAccessToken = await createUser();
      const patientInfo = normalizedPatientInfo();
      await createBooking(patientInfo, tmpAccessToken);
      if (pathname !== ROUTES.RESULT_PAGE) {
        scheduleTracking(clinicDetailsSlice.clinicData.metaPixelCode);
        sendTracking();
      }
      setIsLoading(false);
      dispatch(updateIsDone());
    } catch (error: any) {
      let message = 'Something went wrong. Please try again later';
      if (error === 'Unauthorized') {
        return batch(() => {
          dispatch(replaceStep(STEPS.VERIFICATION));
        });
      }
      if (error?.response?.status === 400) {
        message = error.response.data.message;
      }
      dispatch(
        openToast({
          position: {
            vertical: 'top',
            horizontal: 'center',
          },
          message,
        })
      );
      setIsLoading(false);
    }
  };

  const renderNotYouSection = () => {
    if (previousPage !== STEPS.PATIENT_INFO) {
      return null;
    }

    return (
      <div className={styles['not-you-section']}>
        not you?
        <button className={styles['click-here']} onClick={handleNotYouClick}>
          click here
        </button>
      </div>
    );
  };

  const renderDetailSection = (type: string) => {
    if (
      type === PATIENT_LIST_TYPE.NORMAL &&
      previousPage !== STEPS.PATIENT_INFO
    ) {
      return null;
    }

    return (
      <DetailDescriptionSection
        type={type}
        patients={patients}
        clinicInfo={{
          name: bookingSummaryInfo.clinicName,
          phoneNumber: bookingSummaryInfo.clinicPhoneNumber,
          email: bookingSummaryInfo.clinicEmail,
        }}
      />
    );
  };

  // Patients who are allowed to book next appts
  const renderPatients = () => {
    const bookablePatients = patients.filter((item) => item.isBookable);

    if (bookablePatients.length === 0) return null;

    return (
      <div className={styles['patient-list-section']}>
        <div className={styles['description-section']}>
          {renderDetailSection(PATIENT_LIST_TYPE.NORMAL)}
          {renderNotYouSection()}
        </div>

        <RadioGroup
          className={styles['card-container']}
          value={chosenPatientId || NULL_PATIENT_ID}
          onChange={(e) =>
            dispatch(updateStep3({ chosenPatientId: e.target.value }))
          }
        >
          {bookablePatients.map((patient) => (
            <PatientCard
              key={patient.id}
              isMultipleResult={patients.length > 1}
              patient={patient}
              chosenPatientId={chosenPatientId}
            />
          ))}
        </RadioGroup>
      </div>
    );
  };

  // Patients who are not allowed to book next appts
  const renderPatientsHasFutureApptInFuture = () => {
    const bookablePatients = patients.filter((item) => item.isBookable);
    const newPatients = patients.filter(
      (item) =>
        item.isBookable === false && item.appointment?.isInPast === false
    );

    if (newPatients.length === 0) return null;

    return (
      <div className={styles['patient-list-section']}>
        <div className={styles['description-section']}>
          {renderDetailSection(PATIENT_LIST_TYPE.INCOMPLETE_FUTURE_APPT)}
          {bookablePatients.length === 0 && renderNotYouSection()}
        </div>

        <div className={styles['card-container']}>
          {newPatients.map((patient) => (
            <NewPatientCard key={patient.id} patient={patient} />
          ))}
        </div>
      </div>
    );
  };

  // Patients who are not allowed to book next appts
  const renderPatientsHasIncompleteApptInPast = () => {
    const bookablePatients = patients.filter((item) => item.isBookable);

    const newPatients = patients.filter(
      (item) => item.isBookable === false && item.appointment?.isInPast === true
    );

    if (newPatients.length === 0) return null;

    return (
      <div className={styles['patient-list-section']}>
        <div className={styles['description-section']}>
          {renderDetailSection(PATIENT_LIST_TYPE.INCOMPLETE_PAST_APPT)}
          {bookablePatients.length === 0 && renderNotYouSection()}
        </div>

        <div className={styles['card-container']}>
          {newPatients.map((patient) => (
            <NewPatientCard key={patient.id} patient={patient} />
          ))}
        </div>
      </div>
    );
  };

  const renderPatientWithApptAtTheSameTime = () => {
    const bookablePatients = patients.filter((item) => item.isBookable);

    const patientsWithApptAtTheSameTime = patients.filter(
      (item) =>
        item.isBookable === false &&
        item.isExistingApptWithinRequestedTime === true
    );

    if (patientsWithApptAtTheSameTime.length === 0) return null;

    return (
      <div className={styles['patient-list-section']}>
        <div className={styles['description-section']}>
          {renderDetailSection(PATIENT_LIST_TYPE.SAME_TIME_APPT)}
          {bookablePatients.length === 0 && renderNotYouSection()}
        </div>

        <div className={styles['card-container']}>
          {patientsWithApptAtTheSameTime.map((patient) => (
            <NewPatientCard key={patient.id} patient={patient} />
          ))}
        </div>
      </div>
    );
  };

  // Patients who are not allowed to book next appts
  const renderSinglePatientCard = () => {
    const tmpPatient = patients.find(
      (item) =>
        item.email === patient.email &&
        item.firstName === patient.firstName &&
        item.lastName === patient.lastName &&
        item.isBookable === false
    );

    const patientsWithApptAtTheSameTime = patients.find(
      (patient) => patient.isExistingApptWithinRequestedTime
    );

    const isInPast = tmpPatient?.appointment?.isInPast;

    if (patientsWithApptAtTheSameTime) {
      return (
        <>
          <div className={styles['detail-section']}>
            <div className={styles['title']}>
              <div>
                Looks like this patient has another appointment at the same time
              </div>
              <div className={styles['title-select']}>
                Please go back and change the time of this booking to schedule
                another appointment for any of the users below.
              </div>
            </div>
          </div>
          <NewPatientCard patient={patientsWithApptAtTheSameTime!} />
        </>
      );
    }

    return (
      <>
        <div className={styles['detail-section']}>
          <div className={styles['title']}>
            {isInPast ? (
              <div>
                Looks like this patient already has an incomplete appointment
              </div>
            ) : (
              <div>
                Looks like this patient already has a future appointment
              </div>
            )}
            <div className={styles['title-select']}>
              Please contact {bookingSummaryInfo.clinicName} at&nbsp;
              <b>
                {formatFullPhoneNumber(bookingSummaryInfo.clinicPhoneNumber)}
              </b>{' '}
              or&nbsp;
              <b>{bookingSummaryInfo.clinicEmail}</b> to schedule another
              appointment.
            </div>
          </div>
        </div>
        <NewPatientCard patient={tmpPatient!} />
      </>
    );
  };

  const handleOnDone = () => {
    window.location.reload();
  };

  const isDoneButton = useMemo(() => {
    if (!patient.id && patient.email) {
      // Patient is from New Patient form
      const existingPatientInList = patients.find(
        (item) =>
          item.email === patient.email &&
          item.firstName === patient.firstName &&
          item.lastName === patient.lastName
      );

      if (existingPatientInList) {
        return existingPatientInList.isBookable === false;
      }

      return false;
    }

    const bookablePatients = patients.filter(
      (item) => item.isBookable === true
    );

    return bookablePatients.length === 0;
  }, [patient, patients]);

  return (
    <div className={containerClassName}>
      {patient.email ? (
        <div className={styles['card-container']}>
          <div className={styles['description-section']}>
            {renderNotYouSection()}
            {renderDetailSection(PATIENT_LIST_TYPE.NORMAL)}
          </div>

          {isDoneButton ? (
            renderSinglePatientCard()
          ) : (
            <PatientCard patient={patient} chosenPatientId={chosenPatientId} />
          )}
        </div>
      ) : (
        <>
          {renderPatients()}
          {renderPatientsHasFutureApptInFuture()}
          {renderPatientsHasIncompleteApptInPast()}
          {renderPatientWithApptAtTheSameTime()}
        </>
      )}
      <NavigationBtnControl
        nextBtnTitle={isDoneButton ? 'Done' : 'Submit'}
        onBack={handleOnBackClick}
        onNext={isDoneButton ? handleOnDone : handleOnSubmit}
        isLoading={isLoading}
        {...(!isDoneButton && { 'data-firstin-submit': true })}
      />
    </div>
  );
};

export default Step3;
