import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import moment from 'moment';

import { Divider } from 'components/DentalDivider';
import { PRACTITIONER_SEARCH_TITLE } from 'utils/constants/common';
import useIsMobile from 'hooks/useIsMobile';
import DayViewNavigation from 'components/DayViewNavigation/DayViewNavigation';
import MonthViewNavigation from 'components/MonthViewNavigation/MonthViewNavigation';
import DayView from 'components/DayView/DayView';
import PractitionerDropdownSection from 'components/PractitionerDropdownSection/PractitionerDropdownSection';
import ReasonDropdownSection from 'components/ReasonDropdownSection/ReasonDropdownSection';
import { ITimeSlot } from 'interfaces/timeslotType';
import { getPractitionerOptions } from 'services/APIs/getAllClinicsPractitioners';
import {
  getClinicAvailability,
  IWeeklyTimeSlotsClinicData,
} from 'services/APIs/getClinicAvailability';
import { getPractitionerAvailability } from 'services/APIs/getPractitionerAvailability';

import css from './SingleBooking.module.scss';
import { getFilterDates } from 'utils/dates';
import { parseQueryUrl } from 'utils/parseQueryUrl';
import { getSlotsPer30MinutesForAvailability } from 'utils/updatedSlotsPer30Minutes';
import MonthViewBookNowPanel from 'components/MonthViewBookNowPanel/MonthViewBookNowPanel';
import {
  getDentistIdForSplitScheduling,
  getOperatoryId,
} from 'pages/UpdatedSearchResultPage/utils';
import { IClinicData } from 'redux/clinicDetailsSlice';
import { useAppDispatch } from 'redux/reduxHooks';
import { openToast } from 'redux/ToastSlice';
import {
  IClinicBookingData,
  IPractitionerBookingData,
} from 'pages/ClinicDetailsPage/ClinicDetailsPage';
import {
  ModeOption,
  MYSELF_AND_OTHERS_OPTION,
  MYSELF_OPTION,
  OTHERS_OPTION,
} from '../utils/constants';
import useIsLoadedInsideIframe from 'hooks/useIsLoadedInsideIframe';
import Dropdown from '../Dropdown/Dropdown';
import { IClinicServicesOption } from '../BookNowPanel';

import HeaderSection from '../HeaderSection/HeaderSection';
import axios from 'axios';
import { PATIENT_EXAM_AND_CLEANING } from 'components/DentalMap/utils/constant';
import getPractitionerName from 'utils/getPractitionerName';
import getChairOptimizationForExamCleaning from 'utils/getChairOptimizationForExamCleaning';
import {
  findBestOperatory,
  findPractitionerWithBestOp,
} from 'utils/findOperatory';

interface Props {
  clinicData: IClinicData;
  clinicServicesOptions: IClinicServicesOption[];
  onClickClinicTimeSlot: (data: IClinicBookingData) => void;
  onClickPractitionerTimeSlot: (data: IPractitionerBookingData) => void;
  date: string;
  setDate: React.Dispatch<React.SetStateAction<string>>;
  mode: ModeOption;
  setMode: React.Dispatch<React.SetStateAction<ModeOption>>;
}

interface IPractitionerOptions {
  id: string;
  title: string;
  avatar: string;
  specialties: string[];
  rating: number;
  reviewCount: number;
}

const initialPractitionerValue = {
  id: 'all',
  title: PRACTITIONER_SEARCH_TITLE,
};

const SingleBooking: React.FC<Props> = ({
  clinicData: { id: clinicId, timezone: clinicTimezone, name, slotInterval },
  clinicServicesOptions,
  onClickClinicTimeSlot,
  onClickPractitionerTimeSlot,
  date,
  setDate,
  mode,
  setMode,
}) => {
  const location = useLocation();
  const { serviceSlugNameUrl, codeUrl } = parseQueryUrl(location);

  const isLoadedInsideIframe = useIsLoadedInsideIframe();

  const containerClassname = cx({
    [css['container']]: true,
    [css['container-iframe']]: isLoadedInsideIframe,
  });

  const dispatch = useAppDispatch();

  const [practitionerOptions, setPractitionerOptions] = useState<
    IPractitionerOptions[]
  >([]);

  const [isServiceDropdownOpen, setIsServiceDropdownOpen] = useState(false);

  const [service, setService] = useState(() => {
    const defaultServiceOption = clinicServicesOptions[0];

    if (!serviceSlugNameUrl) {
      return defaultServiceOption;
    }

    const service = clinicServicesOptions.find(
      (service) => service.slug === serviceSlugNameUrl
    );

    if (!service) {
      dispatch(
        openToast({
          position: {
            vertical: 'top',
            horizontal: 'center',
          },
          message: `The reason you are trying to book an appointment for is currently not available. Please choose another reason or contact ${name} for more information.`,
        })
      );
      return defaultServiceOption;
    }

    return service;
  });

  const [practitioner, setPractitioner] = useState(initialPractitionerValue);
  const [isPractitionerDropdownOpen, setIsPractitionerDropdownOpen] =
    useState(false);

  const [clinicData, setClinicData] = useState<IWeeklyTimeSlotsClinicData>(
    {} as IWeeklyTimeSlotsClinicData
  );

  const [timeSlots, setTimeSlots] = useState<ITimeSlot[]>([]); // For  selected practitioner

  const [isClinicTimeSlotLoading, setIsClinicTimeSlotLoading] = useState(true);
  const [isPractitionerTimeSlotLoading, setIsPractitionerTimeSlotLoading] =
    useState(false);
  const [isDayView, setIsDayView] = useState(true);

  const isMobile = useIsMobile();

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchPractitionerOptions = async () => {
      const sortyBy = 'specialist';
      const practitionerOptions = await getPractitionerOptions(
        clinicId,
        service.id,
        sortyBy,
        source.token
      );

      setPractitionerOptions(practitionerOptions);
    };
    fetchPractitionerOptions();

    return () => {
      source.cancel();
    };
  }, [clinicId, service.id]);

  useEffect(() => {
    if (!isDayView) {
      return;
    }

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchClinicAvailability = async () => {
      setIsClinicTimeSlotLoading(true);
      const res = await getClinicAvailability({
        clinicId,
        dates: getFilterDates(date),
        serviceId: service.id,
        timezone: clinicTimezone,
        timeBlocks: [0],
        ...(codeUrl && { code: codeUrl }),
        ...(serviceSlugNameUrl && { serviceSlugName: serviceSlugNameUrl }),
        cancelToken: source.token,
      });
      setClinicData(res);
      setIsClinicTimeSlotLoading(false);
    };

    const fetchPractitionerAvailability = async () => {
      setIsPractitionerTimeSlotLoading(true);
      const res = await getPractitionerAvailability({
        clinicId: clinicId,
        dates: getFilterDates(date),
        practitionerId: practitioner.id,
        serviceId: service.id,
        timezone: clinicTimezone,
        timeBlocks: [0],
        ...(codeUrl && { code: codeUrl }),
        ...(serviceSlugNameUrl && { serviceSlugName: serviceSlugNameUrl }),
        cancelToken: source.token,
      });
      setTimeSlots(res);
      setIsPractitionerTimeSlotLoading(false);
    };

    if (practitioner.id !== 'all') {
      fetchPractitionerAvailability();
    } else {
      fetchClinicAvailability();
    }

    return () => {
      source.cancel();
    };
  }, [
    isDayView,
    clinicId,
    clinicTimezone,
    codeUrl,
    date,
    practitioner.id,
    service.id,
    serviceSlugNameUrl,
  ]);

  const toggleOpenServiceDropdown = (value: boolean) =>
    setIsServiceDropdownOpen(value);

  const toggleOpenPractitionerDropdown = (value: boolean) =>
    setIsPractitionerDropdownOpen(value);

  const setLoadingState = () => {
    isClinicSearch
      ? setIsClinicTimeSlotLoading(true)
      : setIsPractitionerTimeSlotLoading(true);
  };

  const handleChangeServiceOption = (option: IClinicServicesOption) => {
    if (option.id !== service.id) {
      setLoadingState();
      setPractitioner(initialPractitionerValue);
    }
    setService(option);
  };

  const handleChangePractitionerOption = (option: {
    id: string;
    title: string;
  }) => {
    if (option.id === initialPractitionerValue.id) {
      setIsClinicTimeSlotLoading(true);
    }

    setIsPractitionerTimeSlotLoading(true);
    setPractitioner(option);
  };

  const clickClinicTimeSlot = (
    timeSlot: number,
    appointmentDate: string,
    practitionerIds: string[]
  ) => {
    const isExamCleaningService = service.title.includes(
      PATIENT_EXAM_AND_CLEANING
    );

    if (!isExamCleaningService) {
      // LOOK FOR PRACTITIONER HAS SLOT ON SELECTED DATE
      const availablePractitioners = clinicData.doctors.map((doctor) => {
        const operatoryForAppointmentDate = doctor.availableBlocks.find(
          (availableBock) =>
            availableBock.date === appointmentDate &&
            availableBock.blocks.includes(timeSlot)
        );
        return {
          ...doctor,
          availableBlocks: operatoryForAppointmentDate,
        };
      });

      let bestAvailableOperatoryForEachPractitioner = [];

      for (let i = 0; i < availablePractitioners.length; i++) {
        const practitioner = availablePractitioners[i];
        const availableBlockOperatories =
          practitioner.availableBlocks?.availableBlockOperatories;

        if (!availableBlockOperatories) {
          continue;
        }

        const bestOperatory = findBestOperatory({
          selectedSlot: timeSlot,
          availableBlockOperatories,
          serviceDuration: service.duration,
        });

        bestAvailableOperatoryForEachPractitioner.push({
          ...practitioner,
          bestOperatory,
        });
      }

      const bestPractitioner = findPractitionerWithBestOp({
        selectedSlot: timeSlot,
        listOfPractitioners: bestAvailableOperatoryForEachPractitioner,
        serviceDuration: service.duration,
      });

      onClickClinicTimeSlot({
        serviceId: service.id,
        appointmentDate,
        timeSlot,
        operatoryId: bestPractitioner.bestOperatory.operatoryId,
        practitioner: {
          id: bestPractitioner.id,
          title: getPractitionerName(bestPractitioner as any),
          isChosen: false,
        },
        dentist: {
          id: null,
          timeSlot: timeSlot,
          timeSlotType: null,
        },
      });

      return;
    }

    let practitioner = practitionerOptions.find(
      (practitioner) => practitioner.id === practitionerIds[0]
    );

    if (practitioner) {
      const operatoryId = getOperatoryId({
        date: appointmentDate,
        doctorId: practitioner.id,
        timeSlot,
        doctors: clinicData.doctors,
      });

      const dentist = getDentistIdForSplitScheduling({
        date: appointmentDate,
        doctorId: null,
        timeSlot,
        doctors: clinicData.doctors,
      });

      const chairOptimizations = clinicData.doctors.map((doctor) => {
        const availableBlock = doctor.availableBlocks.find(
          (availableBlock) => availableBlock.date === appointmentDate
        )!;
        return getChairOptimizationForExamCleaning({
          id: doctor.id,
          availableBlock,
          selectedSlot: timeSlot,
        });
      });

      onClickClinicTimeSlot({
        serviceId: service.id,
        appointmentDate,
        timeSlot,
        operatoryId,
        practitioner: {
          id: practitioner.id,
          title: practitioner.title,
          isChosen: false,
        },
        dentist,
        chairOptimizations,
      });
    }
  };

  const clickPractitionerTimeSlot = (
    timeSlot: number,
    appointmentDate: string
  ) => {
    const isExamCleaningService = service.title.includes(
      PATIENT_EXAM_AND_CLEANING
    );

    const practitionerTimeSlot = timeSlots.find(
      (timeSlot) => timeSlot.date === appointmentDate
    );

    if (!isExamCleaningService) {
      const bestOperatory = findBestOperatory({
        selectedSlot: timeSlot,
        availableBlockOperatories:
          practitionerTimeSlot?.availableBlockOperatories ?? [],
        serviceDuration: service.duration,
      });

      onClickPractitionerTimeSlot({
        serviceId: service.id,
        appointmentDate,
        timeSlot,
        operatoryId: bestOperatory.operatoryId,
        practitioner: {
          id: practitioner.id,
          title: practitioner.title,
          isChosen: true,
        },
        dentist: {
          id: null,
          timeSlot: timeSlot,
          timeSlotType: null,
        },
      });
      return;
    }

    const operatoryId = getOperatoryId({
      date: appointmentDate,
      doctorId: practitioner.id,
      timeSlot,
      doctors: [
        {
          id: practitioner.id,
          availableBlocks: timeSlots,
        },
      ],
    });

    const dentist = getDentistIdForSplitScheduling({
      date: appointmentDate,
      doctorId: practitioner.id,
      timeSlot,
      doctors: [
        {
          id: practitioner.id,
          availableBlocks: timeSlots,
        },
      ],
    });

    const chairOptimization = getChairOptimizationForExamCleaning({
      id: practitioner.id,
      availableBlock: practitionerTimeSlot!,
      selectedSlot: timeSlot,
    });

    const chairOptimizations = [chairOptimization];

    onClickPractitionerTimeSlot({
      serviceId: service.id,
      appointmentDate,
      timeSlot,
      operatoryId,
      practitioner: {
        id: practitioner.id,
        title: practitioner.title,
        isChosen: true,
      },
      dentist,
      chairOptimizations,
    });
  };

  const handleDateUpdate = (newDate: string) => {
    setLoadingState();
    setDate(newDate);
  };

  const onNavigateToDayView = (dateStr: string) => {
    const parsedDate = moment(dateStr, 'YYYY-MM-DD').toDate().toDateString();
    setDate(parsedDate);
    setIsDayView(true);
  };

  const parsedDate = useMemo(() => {
    if (date && !moment(date, 'YYYY-MM-DD').isValid()) {
      return moment(Date.parse(date)).format('YYYY-MM-DD');
    }
    return date;
  }, [date]);

  const isClinicSearch = practitioner.id === 'all';

  const updatedSlotsPer30MinutesForPractitioner =
    getSlotsPer30MinutesForAvailability(timeSlots, slotInterval);

  const updatedSlotsPer30MinutesForPractice =
    clinicData?.doctors?.map((doctor) => ({
      ...doctor,
      availableBlocks: getSlotsPer30MinutesForAvailability(
        doctor.availableBlocks,
        slotInterval
      ),
    })) ?? [];

  const updatedClinicData = {
    ...clinicData,
    doctors: updatedSlotsPer30MinutesForPractice,
  };

  return (
    <div className={containerClassname}>
      <HeaderSection
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        title={isLoadedInsideIframe ? 'Schedule Online' : 'Select Time '}
        dropdownText={mode.label}
      >
        <Dropdown
          options={[MYSELF_OPTION, MYSELF_AND_OTHERS_OPTION, OTHERS_OPTION]}
          value={mode}
          onChange={setMode}
          {...(!isMobile && {
            modifiers: {
              offset: {
                enabled: true,
                offset: '-25,0',
              },
            },
          })}
        />
      </HeaderSection>
      <div className={css['section-container']}>
        <ReasonDropdownSection
          isOpen={isServiceDropdownOpen}
          options={clinicServicesOptions}
          optionValue={service}
          onChangeIsOpen={toggleOpenServiceDropdown}
          onChangeServiceOption={handleChangeServiceOption}
        />
        <PractitionerDropdownSection
          isOpen={isPractitionerDropdownOpen}
          options={practitionerOptions}
          optionValue={practitioner}
          onChangeIsOpen={toggleOpenPractitionerDropdown}
          onChangePractitionerOption={handleChangePractitionerOption}
          defaultOption={initialPractitionerValue}
        />
      </div>
      <div className={css['navigation-container']}>
        {isDayView ? (
          <DayViewNavigation date={date} onDateUpdate={handleDateUpdate} />
        ) : (
          <MonthViewNavigation date={parsedDate} setDate={handleDateUpdate} />
        )}
        <div className={css['navigation-section']}>
          <button
            className={`${css['navigation-btn']} ${
              !isDayView && css['navigation-btn-inactive']
            }`}
            onClick={() => setIsDayView(true)}
          >
            {isMobile ? 'Day' : 'Day View'}
          </button>
          <button
            className={`${css['navigation-btn']} ${
              isDayView && css['navigation-btn-inactive']
            }`}
            onClick={() => setIsDayView(false)}
          >
            {isMobile ? 'Month' : 'Month View'}
          </button>
        </div>
      </div>
      <Divider orientation="horizontal" className={css['divider']} />
      {isDayView ? (
        <DayView
          isLoading={
            isClinicSearch
              ? isClinicTimeSlotLoading
              : isPractitionerTimeSlotLoading
          }
          date={date}
          onDateUpdate={handleDateUpdate}
          isClinicSearch={isClinicSearch}
          clinicData={updatedClinicData}
          timeSlots={updatedSlotsPer30MinutesForPractitioner}
          onClickClinicTimeSlot={clickClinicTimeSlot}
          onClickPractitionerTimeSlot={clickPractitionerTimeSlot}
          clinicId={clinicId}
          practitionerId={
            practitioner.id !== 'all' ? practitioner.id : undefined
          }
          serviceId={service.id}
        />
      ) : (
        <div
          className={
            isLoadedInsideIframe ? css['month-view-section-iframe'] : ''
          }
        >
          <MonthViewBookNowPanel
            isLoading={
              isClinicSearch
                ? isClinicTimeSlotLoading
                : isPractitionerTimeSlotLoading
            }
            setIsLoading={(isLoading: boolean) => {
              setIsClinicTimeSlotLoading(isLoading);
              setIsPractitionerTimeSlotLoading(isLoading);
            }}
            clinicId={clinicId}
            serviceId={service.id}
            practitionerId={practitioner.id !== 'all' ? practitioner.id : null}
            date={parsedDate}
            clinicTimezone={clinicTimezone}
            onNavigateToDayView={onNavigateToDayView}
            slotInterval={slotInterval}
          />
        </div>
      )}
    </div>
  );
};

export default SingleBooking;
