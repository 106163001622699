import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import { useLocation, useParams } from 'react-router-dom';
import { Content, Header, Layout } from 'components/Layout';
import { NavigationBar } from 'components/NavigationBar';
import {
  clearClinicDetails,
  getClinicBySlugName,
  getPaginatedClinicReviews,
  getPaginatedPractitioners,
} from 'redux/clinicDetailsSlice';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';

import ContactInformation from './ContactInfomation/ContactInfomation';
import ClinicReviews from './ClinicReviews/ClinicReviews';
import AboutClinicInfomation from './AboutClinicInfomation/AboutClinicInfomation';
import ClinicPractitioners from './ClinicPractitioners/ClinicPractitioners';
import styles from './ClinicDetailsPage.module.scss';
import BackToTopButton from 'components/Buttons/BackToTopButton/BackToTopButton';
import BookNowPanel from './BookNowPanel/BookNowPanel';
import useIsMobile from 'hooks/useIsMobile';
import ClinicInfo from 'components/ClinicInfo/ClinicInfo';
import BookingForm from 'components/BookingForm/BookingForm';
import { convertToDate } from 'utils/dates';
import Iframe from './Iframe/Iframe';
import useIsLoadedInsideIframe from 'hooks/useIsLoadedInsideIframe';
import InvalidPage from 'pages/InvalidPage/InvalidPage';
import { parseQueryUrl } from 'utils/parseQueryUrl';
import { DentistInfo } from 'pages/UpdatedSearchResultPage/utils';
import {
  addToCartTracking,
  initiateCheckoutTracking,
} from 'utils/tracking/metaEventTracking';

interface IPractitionerData {
  id: string;
  title: string;
  isChosen: boolean;
}

export interface ChairOptimization {
  doctorId: string;
  operatoryIds: string[];
}

export interface IClinicBookingData {
  serviceId: string;
  appointmentDate: string;
  timeSlot: number;
  operatoryId: string;
  practitioner: IPractitionerData;
  dentist: DentistInfo;
  chairOptimizations?: ChairOptimization[];
}

export interface IPractitionerBookingData {
  serviceId: string;
  appointmentDate: string;
  timeSlot: number;
  operatoryId: string;
  practitioner: IPractitionerData;
  dentist: DentistInfo;
  chairOptimizations?: ChairOptimization[];
}

const DEFAULT_CLINIC_BOOKING_DATA = {
  serviceId: '',
  appointmentDate: '',
  timeSlot: 0,
  operatoryId: '',
  practitioner: {
    id: '',
    title: '',
    isChosen: false,
  },
  dentist: {
    id: null,
    timeSlot: 0,
    timeSlotType: null,
  },
};

const DEFAULT_SORT = 'Newest';

const ClinicDetailsPage = () => {
  const location = useLocation();
  const { serviceSlugNameUrl, codeUrl, dateUrl, isOpen } =
    parseQueryUrl(location);
  const { clinicSlugName } = useParams<{ clinicSlugName: string }>();
  const { id: clinicId } = parseQueryUrl(location);

  const dispatch = useAppDispatch();
  const { clinicData, reviewsData, isLoading, practitionersData, isError } =
    useAppSelector((state) => state.clinicDetailsSlice);

  // temp solution to redirect if the link uses id instead of slug
  const isOldLinkUsedByOnlineIntro = clinicSlugName === 'clinic';

  const isLoadedInsideIframe = useIsLoadedInsideIframe();

  const isMobile = useIsMobile();

  const filterSliceDate =
    useAppSelector((state) => state.filterSlice.date) ||
    moment().format('YYYY-MM-DD');

  const currentDate =
    dateUrl && moment(dateUrl, 'YYYY-MM-DD').isSameOrAfter(moment())
      ? dateUrl
      : filterSliceDate;

  const [reviewsPage, setReviewPage] = useState(1);

  const [practitionersPage, setPractitionersPage] = useState(1);

  const [clinicBookingData, setClinicBookingData] =
    useState<IClinicBookingData>(DEFAULT_CLINIC_BOOKING_DATA);

  const [date, setDate] = useState(currentDate);

  const handleChangeReviewsPage = (page: number) => {
    setReviewPage(page);
  };

  const handleChangePractitionersPage = (page: number) => {
    setPractitionersPage(page);
  };

  const handleCloseClinicBookingForm = () => {
    setClinicBookingData(DEFAULT_CLINIC_BOOKING_DATA);
  };

  useEffect(() => {
    if (!clinicData.metaPixelCode) return;
    const script = document.createElement('script');
    script.append(`!function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('set', 'autoConfig', false); 
    fbq('init', ${clinicData.metaPixelCode});
    fbq.disablePushState = true;
    fbq('track', 'PageView')
    `);
    const noscript = document.createElement('noscript');
    noscript.append(
      `<noscript><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${clinicData.metaPixelCode}&ev=PageView&noscript=1" /></noscript>`
    );
    document.head.append(script);
    document.head.append(noscript);
    return () => {
      document.head.removeChild(script);
      document.head.removeChild(noscript);
    };
  }, [clinicData]);

  useEffect(() => {
    if (!clinicData.googleTagManagerId || !isLoadedInsideIframe) return;

    const googleTagManagerId = clinicData.googleTagManagerId;
    const script = document.createElement('script');
    script.append(`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${googleTagManagerId}')`);
    const noscript = document.createElement('noscript');
    noscript.append(`<iframe src="https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`);
    document.head.append(script);
    document.body.prepend(noscript);

    return () => {
      document.head.removeChild(script);
      document.body.removeChild(noscript);
    };
  }, [clinicData.googleTagManagerId, isLoadedInsideIframe]);

  useEffect(() => {
    if (
      isOpen &&
      isLoadedInsideIframe &&
      clinicData.id &&
      clinicData.metaPixelCode
    ) {
      initiateCheckoutTracking(clinicData.metaPixelCode);
    }
  }, [clinicData.id, clinicData.metaPixelCode, isLoadedInsideIframe, isOpen]);

  useEffect(() => {
    dispatch(
      getClinicBySlugName({
        slug: isOldLinkUsedByOnlineIntro ? clinicId : clinicSlugName,
        date: moment(convertToDate(currentDate)).format('YYYY-MM-DD'),
        ...(serviceSlugNameUrl && { serviceSlugName: serviceSlugNameUrl }),
        ...(codeUrl && { code: codeUrl }),
      })
    );
    return () => {
      dispatch(clearClinicDetails());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      getPaginatedClinicReviews({
        slug: clinicSlugName,
        page: reviewsPage,
        sortBy: DEFAULT_SORT,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewsPage]);

  useEffect(() => {
    dispatch(
      getPaginatedPractitioners({
        slug: clinicSlugName,
        ...(!isMobile && { page: practitionersPage }),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practitionersPage]);

  const renderNavbar = () => {
    return <NavigationBar isAuthButton />;
  };

  const getClinicServices = () => {
    if (clinicData.services) {
      const optionsList = clinicData.services.map(({ name, ...rest }) => ({
        ...rest,
        title: name,
      }));

      if (optionsList.length > 0) {
        return optionsList;
      }
      return [{ title: '', id: '', slug: '', duration: 0 }];
    }
    return [{ title: '', id: '', slug: '', duration: 0 }];
  };

  const handleBookClinicAppointment = (data: IClinicBookingData) => {
    addToCartTracking(clinicData.metaPixelCode);
    setClinicBookingData(data);
  };

  const renderClinicBookingForm = (clinicBookingData: IClinicBookingData) => {
    const service = clinicData.services.find(
      (item) =>
        item.id === clinicBookingData.serviceId ||
        item.childServices?.find(
          (service) => service.id === clinicBookingData.serviceId
        )
    );

    if (service) {
      const serviceName = service.name;
      return (
        <BookingForm
          date={clinicBookingData.appointmentDate}
          timeBlock={clinicBookingData.timeSlot}
          serviceId={clinicBookingData.serviceId}
          clinicId={clinicData.id}
          practitionerId={clinicBookingData.practitioner.id}
          operatoryId={clinicBookingData.operatoryId}
          dentist={clinicBookingData.dentist}
          bookingSummaryInfo={{
            avatar: clinicData.avatar,
            title: clinicData.name,
            clinicName: clinicData.name,
            clinicAddress: clinicData.address,
            clinicEmail: clinicData.email,
            clinicPhoneNumber: clinicData.phone,
            serviceTitle: `${serviceName}${
              clinicBookingData.practitioner.isChosen
                ? ` • ${clinicBookingData.practitioner.title}`
                : ''
            }`,
          }}
          closeBookingDialog={handleCloseClinicBookingForm}
          chairOptimizations={clinicBookingData.chairOptimizations}
        />
      );
    }

    return <></>;
  };

  if (isOldLinkUsedByOnlineIntro && clinicData?.slug) {
    window.location.href = `/${clinicData.slug}`;
    return null;
  }

  if (isLoadedInsideIframe) {
    return (
      <Iframe
        date={date}
        setDate={setDate}
        clinicServicesOptions={getClinicServices()}
      />
    );
  }

  if (clinicBookingData?.appointmentDate && !isLoading) {
    return <Layout>{renderClinicBookingForm(clinicBookingData)}</Layout>;
  }

  if (isError) {
    return <InvalidPage />;
  }

  return (
    <Layout>
      <Header hasBoxShadow>{renderNavbar()}</Header>
      <Content>
        <div className={styles['container']}>
          {!isLoading ? (
            <>
              <BackToTopButton />
              <ContactInformation data={clinicData}>
                <ClinicInfo
                  data={{
                    address: clinicData.address,
                    workingHours: clinicData.workingHours,
                  }}
                  currentDate={date}
                />
              </ContactInformation>
              <div className={styles['wrapper']}>
                <div className={styles['wrapper-left']}>
                  <BookNowPanel
                    clinicData={clinicData}
                    clinicServicesOptions={getClinicServices()}
                    onClickClinicTimeSlot={handleBookClinicAppointment}
                    onClickPractitionerTimeSlot={handleBookClinicAppointment}
                    date={date}
                    setDate={setDate}
                  />
                  {isMobile && (
                    <ClinicReviews
                      reviewIds={reviewsData.reviews.ids}
                      currentPage={reviewsPage}
                      onChangePage={handleChangeReviewsPage}
                      pageCount={reviewsData.totalPages}
                      isLoading={reviewsData.isReviewsLoading}
                    />
                  )}
                  <AboutClinicInfomation
                    clinicName={clinicData.name}
                    description={clinicData.description}
                    services={clinicData.services}
                  />
                  <ClinicPractitioners
                    clinic={clinicData}
                    practitionerData={practitionersData.practitioners}
                    totalPractitioners={practitionersData.totalPractitioners}
                    currentPage={practitionersPage}
                    pageCount={practitionersData.totalPages}
                    onChangePage={handleChangePractitionersPage}
                    isRenderInsideDetailPage
                  />
                </div>
                {!isMobile && (
                  <div className={styles['wrapper-right']}>
                    <ClinicInfo
                      data={{
                        address: clinicData.address,
                        workingHours: clinicData.workingHours,
                      }}
                      currentDate={date}
                    />
                    <ClinicReviews
                      reviewIds={reviewsData.reviews.ids}
                      currentPage={reviewsPage}
                      onChangePage={handleChangeReviewsPage}
                      pageCount={reviewsData.totalPages}
                      isLoading={reviewsData.isReviewsLoading}
                    />
                  </div>
                )}
              </div>
            </>
          ) : (
            <CircularProgress size={48} className={styles['loading']} />
          )}
        </div>
      </Content>
    </Layout>
  );
};

export default ClinicDetailsPage;
